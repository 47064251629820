import { css } from 'pretty-lights'

import logo from './itis.gif'
import splat from './splat.png'
import droplet from './drop.png'
import './App.css'

const pee = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: ${splat};
`
const dropPane = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  text-align: center;
  width: 100%;
`
const gif = css`
  position: fixed;
  top: 35%;
  left: 40%;
  height: 40vmin;
  pointer-events: none;
  z-index: 4;
`

const App = () => {
  return (
    <div className={pee}>
      <div className={dropPane}>
        <PissStorm />
        <img src={logo} className={gif} alt="logo" />
        <img className="splatter" src={splat} alt="yellow splatter" />
      </div>
    </div>
  )
}

const drop = (z, x, vary) => css`
  width: 15px;
  position: absolute;
  left: ${x}%;
  z-index: ${z};
  animation-name: fall;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  animation-duration: 0.7s;
  animation-delay: ${vary}s;

  @keyframes fall {
    0% {
      transform: translateY(-120vh);
    }
    45% {
      transform: translateY(0%);
      opacity: 1;
    }
    46% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`
const PissStorm = () => {
  let raindrops = []
  for (let i = 0; i < 10; i++) {
    raindrops.push(i % 3)
  }
  return raindrops.map((z, i) => {
    const r = Math.floor(Math.random() * 100)
    const vary = i % 2 === 0 ? i : -i
    console.log('math floor x', r, 'vary', vary)
    return <Drop key={`pee-droplet-${i}`} z={z} x={r} vary={vary} />
  })
}
const Drop = ({ z, x, vary }) => {
  const d = drop(z, x, vary)
  return <img className={d} src={droplet} alt="falling yellow droplet" />
}

export default App
